<template>
  <div class="friends" v-if="!isProcessing">
    <friends-user class="friends__user"
                  v-for="friend in friends" :key="friend.uid" :uid="friend.uid" />
    <p class="friends__warning" v-if="friends.length === 0">プロフィールから友達をフォローしよう！</p>
  </div>
</template>

<script>
import FriendsUser from '@/components/friends/user'

export default {
  name: 'friends',
  components: { FriendsUser },
  async mounted () {
    // フォロー情報が未取得の場合はフォロー情報を取得する
    if (!this.hasGotFollows) await this.$store.dispatch('follows/getFollows', this.uid)

    // フォロー中のユーザー情報およびユーザー名がない場合は取得
    const promises = []
    this.follows.forEach(doc => {
      const uid = doc.follow
      const user = this.$store.getters['users/user'](uid)
      const uname = this.$store.getters['uidUname/uname'](uid)
      if (!user) promises.push(this.$store.dispatch('users/getUser', uid))
      if (!uname) promises.push(this.$store.dispatch('uidUname/getUname', uid))
    })
    await Promise.all(promises)

    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 同期処理が必要な情報を取得中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    },
    /**
     * @return {String} ログイン者のユーザーID
     */
    uid () {
      return this.$store.getters['auth/uid']
    },
    /**
     * @param {String} uid ユーザーID
     * @return {String} ユーザー名
     */
    unames () {
      return this.$store.getters['uidUname/unames']
    },
    /**
     * @param {String} uid ユーザーID
     * @return {Object} ユーザー情報
     */
    users () {
      return this.$store.getters['users/users']
    },
    /**
     * @return {Boolean} フォロー情報を取得済みかどうか
     */
    hasGotFollows () {
      return this.$store.getters['follows/hasGot']
    },
    /**
     * @return {Object[]} フォロー情報の一覧
     */
    follows () {
      return this.$store.getters['follows/follows']
    },
    /**
     * @return {Object[]} ユーザー情報
     */
    friends () {
      const friends = []
      this.follows.forEach(doc => {
        friends.push(Object.assign({ uid: doc.follow }, this.users[doc.follow]))
      })
      return friends.sort((a, b) => {
        if (a.name > b.name) return -1
        if (a.name < b.name) return 1
        return 0
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.friends {
  width: 100vw;
  max-width: $max_width;
  padding: $padding_height $padding_width;
  margin: $header_height auto $footer_height auto;
  &__warning {
    margin: 10px 0 0;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
  }
}
</style>
