<template>
  <div class="friends-user" @click="$router.push({ name: 'profile', params: { uname: uname } })">
    <v-img class="friends-user__icon" :src="user.icon" />
    <div class="friends-user__info">
      <p class="friends-user__info__name">{{ user.name }}</p>
      <p class="friends-user__info__position">{{ user.position }}</p>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    // ユーザーID
    uid: {
      type: String,
      required: true
    }
  },
  computed: {
    /**
     * @return {String} ユーザー名
     */
    uname () {
      return this.$store.getters['uidUname/uname'](this.uid) || this.uid
    },
    /**
     * @return {Object} ユーザー情報
     */
    user () {
      return this.$store.getters['users/user'](this.uid)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/color.scss';
@import '@/assets/sass/size.scss';

.friends-user {
  display: flex;
  align-items: center;
  width: 100vw;
  max-width: $max_width;
  padding: $padding_height $padding_width;
  margin-left: -$padding_width;
  border-bottom: 1px solid $light_gray_color;
  &__icon {
    flex: none;
    width: 45px;
    height: 45px;
    background-color: $light_gray_color;
    border-radius: 50%;
  }
  &__info {
    flex: 1;
    margin: 0;
    margin-left: 10px;
    vertical-align: middle;
    &__name {
      margin: 0;
      font-size: 1.4rem;
      font-weight: bold;
    }
    &__position {
      margin: 2px 0 0;
      font-size: 1rem;
      color: $orange_color;
    }
  }
}
</style>
